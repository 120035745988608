/* src/components/Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  max-width: 500px; /* Adjust as needed */
  width: 100%;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dee2e6;
}

/* .popup-header .modal-title {
  margin: 0;
} */

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.btn-close::before {
  content: "×";
  font-size: 1.5rem;
  color: black;
}

.popup form {
  margin-top: 10px;
}

.login-box form a {
  position: relative;
  color: #5dc4eb !important;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 20px;
  letter-spacing: 1px;
}

.login-box a span {
  position: absolute;
  display: block;
}
.login-box p {
  font-weight: 600;
  font-size: 16px;
}

.login-box a span:first-child {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #5dc4eb);
  animation: 1s linear infinite btn-anim1;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  100%,
  50% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #5dc4eb);
  animation: 1s linear 0.25s infinite btn-anim2;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  100%,
  50% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #5dc4eb);
  animation: 1s linear 0.5s infinite btn-anim3;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  100%,
  50% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #5dc4eb);
  animation: 1s linear 0.75s infinite btn-anim4;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  100%,
  50% {
    bottom: 100%;
  }
}
