.sidebar-info {
    background: linear-gradient(to left bottom, #e4f3f9, #fff7fc);
    padding: 15px 30px 30px;
    border: 2px solid #00bdff;
    border-radius: 10px;
    text-align: left;
  }
  
  .sidebar-info ul {
    list-style-type: none;
    padding: 0;
  }
  

  
  .sidebar-info ul li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .sidebar-info ul li a:hover {
    color: #007bff; /* Hover color for links */
  }
  
 
  
  
