 .mobile.IntegrationsSection {
    background: rgba(252, 247, 243, 1);
  }
  
   .mobile .integration-box {
    aspect-ratio: 1;
  border: 1px solid #003d3d0a;
  border-radius: 10px;
  background-color: #f7f2ed;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
   .mobile .integration-box h2 {
   font-size: 16px!important;
  }
  
  .mobile .integration-image {
    width: 100%;
    max-width: 40px;
  }
  .mobile
  .content-box {
    background: transparent;
    /* color: #fff; */
    padding: 20px;
    /* border-radius: 10px; */
  }
  .mobile .integration-box img {
    width: 40px;
    height: 40px;
}