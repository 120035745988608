@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.logo-carousel {
  width: 100%;
  margin: 0 auto;
}

.logo-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-carousel .logo-image {
  max-width: 150px;
  padding: 0px;
}
.logo-image {
  width: 100%; /* Make sure the image takes the full width of its container */
  height: auto; /* Maintain aspect ratio */
}

.logo-carousel .slick-slide {
  display: flex; /* Ensures proper alignment of logos */
  justify-content: center; /* Center logos in the slide */
}

@media (max-width: 768px) {
  .logo-image {
    max-height: 60px; /* Adjust height for smaller screens if necessary */
  }
}

@media (max-width: 576px) {
  .logo-image {
    max-height: 50px; /* Further adjust height for mobile screens */
  }
}
