.onboarding-section {
    --offset: 20px;
    --header-height: 90px;
    text-align: start;
    width: 100%;
    height: calc(100vh - (var(--header-height) - var(--offset)));
    position: sticky;
    top: var(--header-height);
    scroll-snap-align: start;
    padding-bottom: var(--offset);
    border-radius: var(--offset) var(--offset) 0 0;
    padding: 5px;
}

