/* Basic styles */


.top-bar {
  position: sticky;
  top: 0; /* Stick to the top of the page */
  z-index: 1000; /* Ensures it stays above other elements */
  background-color: #fff; /* Background color for better visibility */
  padding: 4px 15px; /* Padding to add some space */
  display: flex;
  justify-content: space-between; /* Space between left and right content */
  align-items: center; /* Center the logos vertically */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

.sales-hub .logo {
  height: auto;
  width: 200px;
}

/* Main Container */
 .sales-hub .main-container {
  display: flex;
  min-height: 100vh;
  z-index: 99;
}

/* Sidebar Styling */
 .sales-hub .sidebar {
  width: 250px;
  background-color: #f3f3f3;
  padding: 20px;
  color: #000;
  position: sticky;
  top: 80px; /* to stay below the sticky bar */
  height: 100vh;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
  background: 0 0
}
.sales-hub .sidebar h3 {
  color: #fff;
  margin-bottom: 20px;
}

 .sales-hub .sidebar ul {
  list-style: none;
  padding-left: 0;
}

 .sales-hub.sidebar ul li {
  margin-bottom: 10px;
}

.sales-hub .sidebar ul li a {
  color: #00bdff;
  text-decoration: none;
  padding: 10px;
  display: block;
  border-radius: 5px;
  transition: background 0.3s;
}

.sales-hub .sidebar ul li a:hover {
  background-color: #e5e7eb;
  border-radius: 7px;
  color: #000;
}

/* Content Area */
.sales-hub .content {
  flex: 1;
  padding: 20px;
}

.sales-hub .content-section {
  margin-bottom: 30px;
}

.sales-hub .content-section h4 {
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sales-hub .sidebar {
    width: 100%;
    position: static;
    height: auto;
    padding: 10px;
  }

  .sales-hub .main-container {
    flex-direction: column;
  }

  .sales-hub .top-bar {
    padding: 10px;
  }

  .sales-hub .top-bar-left, .top-bar-right {
    text-align: center;
  }

  .sales-hub .content {
    padding: 10px;
  }
}
