/* Ensure the Swiper container has a defined size */
.swiper-container {
    width: 100%;
    height: auto;
    overflow-y: hidden;
    /* Hide vertical scrollbar */
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for WebKit-based browsers */
  }
  
  /* Swiper buttons styling */
  .swiper-button-next,
  .swiper-button-prev {
    color: #000;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    /* Ensure they are above other content */
  }
  
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
    color: #000;
    /* Adjust color to ensure visibility */
  }
  
  .swiper-button-next {
    right: 10px;
  }
  
  .swiper-button-prev {
    left: 10px;
  }
  
  .bg-gray6 {
    background: rgba(20, 77, 103, 1);

  }
  
  .img-cover img {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    border: 2px solid rgba(12, 222, 198, 0.14)
  }
  
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .blog .card::after,
  .blog-page.style-5 .related-postes-slider .swiper-slide-active::after,
  .blog-page.style-5 .related-postes-slider .swiper-slide-prev::after {
    position: absolute;
    content: "";
    right: -40px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: rgba(153, 153, 153, 0.2666666667);
  }
  @media (max-width: 768px) {
    .blog .card::after,
    .blog-page.style-5 .related-postes-slider .swiper-slide-active::after,
    .blog-page.style-5 .related-postes-slider .swiper-slide-prev::after {
      display: none;
    }
  }
  
  
  .blog .card {
    text-align: left;
  }
  
  .blog h5 a {
    font-size: 18px;
    font-weight: bold;
    background: linear-gradient(92.72deg, #144D67 24.58%, #22CEBA 102.91%);
    background-clip: text; /* Standard property */
    -webkit-background-clip: text; /* WebKit-specific */
    -webkit-text-fill-color: transparent;
    color: transparent; /* Fallback for browsers that do not support background-clip */
    margin: 5px 0;
}

  
  .blog .card,
  .card .icon,
  .sidebar h4,
  .sidebar h5,
  .sidebar ul li {
    text-align: left;
  }
  
  .blog_Slider .card{
    padding: 10px!important;
  
  }
  
  .blog .small,
  small {
    font-size: 13px;
    margin-top: 15px;
    line-height: 1.7;
  }
  