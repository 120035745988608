
.service-box .icon img{

    width: 60px!important;
    height: 60px!important;
    -o-object-fit: cover;
    object-fit: cover;
}
.services.style-1 .service-box {
    position: relative;
    text-align: left;
    z-index: 5;
    padding: 30px;
    border: 1px solid rgba(153, 153, 153, .2);
    border-radius: 8px;
    display: block;
    background-color: #fff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.services.style-1 .service-box h5 a{
 
    display: flex;
    color: #000;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.integration_content ul li{
 padding: 8px 0px;

}
.specific-section ul li {
    padding: 6px 10px;
    border-radius: 10px;
}