.IntegrationsSection {
  background: rgba(252, 247, 243, 1);
}

.integration-box {
  aspect-ratio: 1;
  border: 1px solid #003d3d0a;
  border-radius: 1.2rem;
  background-color: #f7f2ed;
  max-width: 80px;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.content-box {
  background-color: transparent;
  /* border: 1px solid #003d3d0a; */
  color: #000;
  padding: 0px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}
.content-box h2 {
  margin-top: 0 !important;
  font-size: 28px;
  align-items: center;
  color: rgba(19, 61, 99, 1);
}
.content-box .btn-grad {
  background: linear-gradient(94.23deg, #0f8597 -2.83%, #0a9e8d 98.65%);
  color: #fff;
  margin: 10px;
  padding: 13px 40px;
  border-radius: 15px;
}
.integration-box img{
    width: 80px;
    height: 80px;
}


