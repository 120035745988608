/* Basic styling for header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: #fff;
  position: sticky;
  top: 42px;
  /* top: 85px; */
  z-index: 1000;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  /* Ensures the header stays on top of other content */
}
.dropdown-item:active {
  background-color: transparent;
}
@media (min-width: 992px) {
  .header .dropdown-menu {
    position: absolute;
    top: 49px !important; /* Ensure no gap is created */
    left: -150px !important;
    display: block; /* Keep the dropdown visible */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  @media (min-width: 992px) {
    .header .dropdown-menu.custom-dropdown {
      left: -299px !important; /* Increase the left value to move it more to the left */
    }
  }

  .header .dropdown:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }
}

.custom-divider {
  position: relative;
  color: #5dc4eb;
  font-size: 15px;
  font-weight: 700;
  padding: 0 0 12px 10px;
  margin-bottom: 13px;
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  transition: 0.3s;
}
.subline {
  font-size: 11px;
  margin-left: 48px;
  letter-spacing: 1px;
  margin-top: -2px;
  display: block;
  color: #666;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  max-width: 1291px;
  width: 100%;
  border: none;
  z-index: 10;
  margin: auto;
  opacity: 1;
}

/* Logo styling */
.logo {
  width: 200px;
}

.dropdown-toggle::after {
  position: relative;
  top: 3px;
  opacity: 0.8;
  content: "\f078";
  font-weight: 900;
  font-family: "Font Awesome 5 pro";
  border: 0;
  font-size: 80%;
}

.button {
  margin-left: auto;
}

/* Menu styling */
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top-navbar {
  position: relative;
  z-index: 9999;
  background: #f3fbff;
}

.nav-scroll {
  background: #fff !important;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 0;
  position: fixed !important;
  top: -100px !important;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  border-radius: 0 !important;
  margin: 0 !important;
}

.menu ul li {
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  margin: 6px 20px;
  /* Adjust spacing between menu items */
}

.menu ul li:last-child {
  margin-right: 0;
  /* Remove margin from last menu item */
}

.menu ul li a {
  text-decoration: none;
  color: #333;
  /* Menu item text color */
  font-size: 16px;
  /* Adjust font size */
}

.menu ul li a:hover {
  color: #007bff;
  /* Hover color */
}

/* Button styling */
.button {
  margin-left: auto;
}

.button a {
  text-decoration: none;
  color: #fff;
  background-color: #5dc4eb;
  padding: 10px 25px;
  font-weight: 500;
  font-size: 14px;
}

.button a:hover {
  background-color: #4ac1ed;
  /* Hover background color */
}

/* src/components/Header.css */

/* Enable dropdowns on hover */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

/* Custom styles for dropdown arrows */
.header .dropdown-toggle::after {
  margin-left: 0.5rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  top: 2px;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* Customize the dropdown menu appearance */
.header .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.0666666667);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.0666666667);
  padding: 15px 0;
  background: #fff;
  border: 1px solid #ced3d9;
  min-width: auto;
  border-radius: 15px;
  top: 100%;
}

.header .dropdown-item img {
  margin-right: 10px;
  /* Adjust if necessary */
  width: 30px;
  /* Increase the width to make the image larger */

  vertical-align: middle;
  /* Aligns the image vertically with the text */
}

.dropdown-menu {
  min-width: auto;
  background: #fff;
  border: 1px solid #ced3d9;
  padding: 15px 0;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.0666666667);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.0666666667);
  top: 100%;
}

.dropdown-item {
  padding: 0.25rem 0.25rem;
  clear: both;
  font-size: 14px !important;
}

/* Styles for dropdown menu columns */
.dropdown-menu-columns .row {
  flex-wrap: nowrap;
}

.internalpage_link {
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  background: 0 0;
  color: #5dc4eb;
  margin: 5px 0 5px 5px;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.div-block-277 {
  height: 45px;
  color: #5dc4eb;
  border-right: 1px solid var(--light-grey);
  background-color: #f3f3f3;
  justify-content: flex-start;
  align-self: auto;
  align-items: center;
  margin-top: 20px;
  padding-left: 35px;
  transition: 0.2s ease-out;
  display: flex;
}
.bg-gr {
  background: linear-gradient(85.35deg, #093055 0.77%, #12dfdf 98.65%);
  box-shadow: 20px 20px 50px 0px rgba(25, 61, 131, 0.05);
  backdrop-filter: blur(12px);
  padding: 4px 0px;
}
.bg-gr .menu .nav-item .nav-link {
  text-decoration: none;
  color: #fff !important;
  font-size: 16px;
}
.bg-gr .btn {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 7px 20px;
  box-shadow: 0px 1px 5.3px 0px rgba(34, 206, 186, 0.39);
  border: 0px solid rgba(255, 255, 255, 1);
}
.bg-gr .btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  box-shadow: 0px 1px 5.3px 0px rgba(34, 206, 186, 0.39);
}
