@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* Normalize/Reset CSS */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  font-family: 'Poppins', sans-serif;
 
}
body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin that may cause overflow */
  padding: 0; /* Remove default padding that may cause overflow */
  max-width: 100vw; /* Ensure the body width does not exceed viewport width */
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 767px) {
  /* Adjust specific styles for mobile */
  html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    font-family: 'Poppins', sans-serif;
   
  }
}
/* Global scrollbar styling */
html::-webkit-scrollbar {
  width: 10px; /* Vertical scrollbar width */
  height: 8px; /* Horizontal scrollbar height - make this smaller */
  background: #f1f1f1;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #5dc4eb;
  border-radius: 10px;
}

html::-webkit-scrollbar-corner {
  background: #999;
  border-radius: 10px;
}

html {
  scroll-behavior: smooth;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
}

/* Apply Nunito Sans to paragraphs */
p {
  font-family: 'Nunito Sans', sans-serif;
}
/* Scrollbar styling for specific elements */
.scrollable-element::-webkit-scrollbar {
  width: 10px; /* Vertical scrollbar width */
  height: 8px; /* Horizontal scrollbar height */
  background: #f1f1f1;
  border-radius: 10px;
}

.scrollable-element::-webkit-scrollbar-thumb {
  background: #5dc4eb;
  border-radius: 10px;
}

.scrollable-element::-webkit-scrollbar-corner {
  background: #999;
  border-radius: 10px;
}

.scrollable-element {
  overflow: auto; /* Enable both X and Y scrolling */
  scroll-behavior: smooth;
  width: 100%; /* Adjust width as needed */
  height: 100%; /* Adjust height as needed */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
