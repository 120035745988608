.partner-with-us-page h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.form-group .btn {
  position: absolute;
  left: 33%;
  bottom: 88px;
}

form .form-group .icon {
  position: absolute;
  left: 30px;
  bottom: 93px;
  font-size: 18px;
}
.form-group .btn {
  position: absolute;
  left: 33%;
  bottom: 88px;
}

form .form-group .icon {
  position: absolute;
  left: 30px;
  bottom: 93px;
  font-size: 18px;
}
.form-group .btn {
  position: absolute;
  left: 33%;
  bottom: 88px;
}

form .form-group .icon {
  position: absolute;
  left: 30px;
  bottom: 93px;
  font-size: 18px;
}
.latest_story .story_box .story_img img {
  width: 100%;
  margin-top: -79px;
}

.partner-with-us-page p {
  font-size: 1rem;
}

.partner {
  position: relative;
  padding: 60px 0 80px;
  overflow: hidden;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#fff),
    color-stop(74%, #edf4fe),
    to(#e7f1ff)
  );
  background: -o-linear-gradient(bottom, #fff 0, #edf4fe 74%, #e7f1ff 100%);
  background: linear-gradient(to top, #fff 0, #edf4fe 74%, #e7f1ff 100%);
}

.form-group input {
  width: 100%;
  padding: 16px 18px 16px 46px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 30px;
}

.latest_story .story_box {
  background-color: #fff;
  text-align: center;
  height: 100%;
  border-radius: 30px;
  box-shadow: 0 0 10px #e5e5e5;
  transition: 0.5s;
  padding: 30px 20px;
}

.latest_story .story_box .story_text h3 {
  color: #5dc4eb;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
}

.cta-partner {
  border: 1px solid #ced3d9;
  border-radius: 10px;
}
.solution-cards img {
  text-align: left;
  width: 25%;

  align-items: start;
}
.solution-cards {
  border: 2px solid #54cdeb;
  border-radius: 20px;
  height: 100%;
  padding: 17px;
}
