.main-wrapper {
  padding: 20px;
}

.top-section.heading {
  text-align: center;
  font-size: 24px;
  color: #333;
}

.content-main {
  margin-top: 20px;
}

.content {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center;
}

.content-div {
  box-shadow: -1px 2px 8.3px 4px rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 1);
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  backdrop-filter: blur(2px);
}

.advantage.subheading {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: rgba(43, 42, 42, 1);
}

.content-text.text {
  text-align: left;
  font-family: Jomolhari;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555;
  font-size: 24px;
  margin-top: 10px;
}

.author-details-outer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  background-color: rgba(230, 243, 255, 1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.author-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.author-details-inner {
  margin-left: 10px;
}

.author-name.text {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.author-pos.text {
  font-size: 14px;
  color: #888;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.choice {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.choice_btn {
  margin: 0 10px;
  cursor: pointer;
  text-align: center;
}

.choice_img img {
  width: 30px;
  height: 30px;
}

.choice_text.text {
  font-size: 14px;
  margin-top: 5px;
}
.stroy-card {
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(208, 208, 208, 0.78);
  box-shadow: -1px -1px 11px 4px rgba(24, 175, 128, 0.16);
  padding: 15px 20px;
  border-radius: 13px;
  text-align: left;
  color: rgba(43, 42, 42, 1);
}
.stroy-card:hover {
  border: 2px solid rgba(140, 229, 219, 1);
  box-shadow: -1px -1px 11px 4px rgba(24, 175, 128, 0.16);
}
.stroy-card p {
  margin-bottom: 0;
}
.stroy-card h5 {
  color: rgba(43, 42, 42, 1);
  font-weight: 600;
  font-size: 28px;
}
.stroy-card img {
  width: 32px;
  border-radius: 50px;
  padding: 8px;
  margin-bottom: 10px;
}
.stroy-card_1 img {
  background: rgba(92, 155, 182, 1);
}
.stroy-card_2 img {
  background: rgba(34, 206, 186, 1);
}
.stroy-card_3 img {
  background: rgba(61, 202, 195, 1);
}
.stroy-card_4 img {
  background: rgba(232, 190, 172, 1);
}
.stroy-card_5 img {
  background: rgba(255, 198, 184, 1);
}
.stroy-card_6 img {
  background: rgba(112, 208, 163, 1);
}
.reason_btn {
  background: linear-gradient(90deg, #ec8721 0%, #f7b52f 100%);
  color: #fff;
  border-radius: 30px;
  padding: 7px 10px;
}
.reason_btn_2 {
  background: linear-gradient(90deg, #14a363 0%, #32c992 100%);
  border-radius: 30px;
  padding: 7px 10px;
  color: #fff;
}
.btn-pop {
  background: linear-gradient(91.8deg, #22ceba -1.7%, #144d67 110.17%);
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
}
.btn-pop:hover {
  color: #fff;
}

.wp-popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #fff;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.gradient {
  background: linear-gradient(91.8deg, #22ceba -1.7%, #144d67 110.17%);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.quotes-img{
  float: end!important;
}
.chat-icon{
  width: 70px;
}
.head-gradient {
  font-size: 1.6rem;
  font-weight: bold;
  background: linear-gradient(92.72deg, #144D67 24.58%, #22CEBA 102.91%);
  -webkit-background-clip: text; /* For WebKit-based browsers (Chrome, Safari, etc.) */
  background-clip: text; /* Standard property for future compatibility */
  -webkit-text-fill-color: transparent; /* WebKit-specific property to make text transparent */
}

 .head-gradient span{
  background: linear-gradient(92.53deg, #30C78F 67.42%, #072541 106.78%);

background-size: 200% auto;
background-clip: text;
color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
animation: textclip 2s linear infinite;
padding: 0px 0px;
border-radius: 10px;
white-space: nowrap;
font-weight: 700;
overflow: hidden;
}
.top-section.heading {
  text-align: center;
  color: #333;
  font-size: 1.6rem;
  font-weight: bold;
}
.top-section.heading span{

  background: linear-gradient(93.94deg, #072541 10.51%, #22CEBA 55.89%);
 background-size: 200% auto;
background-clip: text;
color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
}
.reaosn_icon{
  width: 40px;

}