
  
  .knowledge-center-page h1 {
   
    margin-bottom: 20px;
  }
  
  .knowledge-center-page p {
    font-size: 1rem;
  }
  .knowledge-center-page a {
    color: #54ceeb;
    font-size: 1rem;
  }
  .knowledge_card{
      border: 1px solid rgba(153, 153, 153, .2666666667);
      border-radius: 5px;
      padding: 25px;
      display: flex;
      background-color: #fff;

  }
  .knowledge_card h6{
    font-size: 1.5rem;
      text-align: left;
  

  }
  .knowledge_card .user_img {
    margin-right: 25px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.career-details-page.style-5 .summary-card {
  border: 1px solid rgba(153, 153, 153, .2);
  border-radius: 5px;
  padding: 40px;
  position: sticky;
  top: 123px;
}

.career-details-page.style-5 .summary-card li {
  font-size: 14px;
  list-style-type: none;
  color: #999;
  margin: 15px 0;
}
.career-details-page.style-5 .summary-card ul {
padding-left: 0;
} 
.career-details-page.style-5 .summary-card ul li a {
  font-size: 14px;
  color: #999;
}
.career-details-page.style-5  h6 {
  font-size: 20px;
  font-weight: 700;
}

.brd-gray {
  border-color: rgba(0, 0, 0, .1333333333) !important;
}.color-999 {
  color: #999 !important;
}
  .career-details-page.style-5 .jop-details .jop-info .tags a, .tags a {
  font-weight: 600;
  padding: 6px 15px;
  margin: 0px 5px;
  border-radius: 30px;
  background-color: #edf5ff;
  color: #157efb;
  font-size: 14px;
}
.fa-check-circle{
  color: #54ceeb;

}