.heading{

    font-size: 41px;
}.number-card {
    border-right: 1px solid rgba(153, 153, 153, .2);    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
}

.number-card h2 {
    color: #27afc8;
    font-size: 45px;
    font-weight: 700;
}.number-card .text {
    font-size: 15px;
    color: #000;
    text-align: start;
    line-height: 1.5;
    text-transform: capitalize;
}
 .img-icon {
    margin-bottom: 22px;
}
.img-icon img {
    width: 60px;
    height: 60px;
    -o-object-fit: cover;
    object-fit: cover;
}
.service-card {
    padding: 16px;
    text-align: start;
    box-shadow: 0 -16px 32px 0 rgba(0, 0, 0, .031372549);
    border-radius: 29px;
    background-color: #fff;
    position: relative;
    z-index: 5;

}.service-card h3 {
    font-size: 20px;
    font-weight: 500;
    color: #5dc4eb;
    margin-bottom: 15px;
}
.bg-gray2{
    background: linear-gradient(to left bottom, #FFF7FC, #f3fbff);

}