.qr-code {
  background: rgba(20, 77, 103, 1);
  box-shadow: -1px -1px 18px 1px rgba(11, 165, 181, 0.3);
  border-radius: 10px;
  padding: 15px 25px;
}
.qr-code-inner {
  background-color: #fff;
  padding: 20px;
  box-shadow: -1px -1px 18px 1px rgba(11, 165, 181, 0.3);
  border-radius: 10px;
}
.down-qr-code {
  background: rgba(223, 224, 226, 1);
  border-radius: 8px;
}
.qr-code label {
  font-size: 12px;
  color: rgba(34, 14, 39, 1);
  font-weight: 600;
  margin-bottom: 10px;
}
.qr-code .form-control {
  border-radius: 10px;
}
