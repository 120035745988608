.video-slider-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;

  }
  
  .video-card {
    padding: 10px;
    width: 92%!important;
    /* background: #f9f9f9; */
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .video-card iframe {
    border-radius: 8px;
    max-height: 300px;
  }
  .video-slider-container .slick-dots{
    bottom: -45px!important;
    
  }
  .ytp-large-play-button {
   width: 40px!important;
   height: 40px!important;
  }

  .video-wrapper {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
   
    transition: opacity 0.3s ease; /* Smooth overlay appearance */
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* Prevent the text from wrapping */
    border: 0;
  }
 .bg-white{
  background-color: #fff;
  box-shadow: -1px 1px 7.7px 4px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  margin-top: 20px;

 }
 .rocket{
  width: 30px;
  margin-left: 5px;
 }
 .video-info {
  text-align: center;
  padding: 10px;
}

.video-title {
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(92.72deg, #144D67 24.58%, #22CEBA 102.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px 0;
}


.video-description {
  font-size: 13px;
  font-weight: 600;
  color: rgba(30, 29, 29, 0.73);
  
}
