.TabSection2::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 100px;
  background-size: cover;
  z-index: 1;
  pointer-events: none;
}
.TabSection2 .tab-content {
  padding: 30px;
  border-radius: 30px;
  color: #fff;
  background: #14304b;
  border-top: none;
}
.next-btn,
.pre-btn {
  border-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid;
  border-image-slice: 1; /* Ensures the gradient is applied across the border */
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.08) inset !important;
  border-radius: 50%;
  width: 38px !important;
  height: 38px !important;
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TabSection2 .nav-item {
  padding: 0px 12px;    
}
.TabSection2 .nav-item .nav-link {
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 14px;
  border-radius: 30px;
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.08) inset;
}
.TabSection2 .nav-item .nav-link.active {
  background: linear-gradient(
    94.82deg,
    rgba(17, 137, 153, 0.94) 7.33%,
    rgba(32, 200, 184, 0.94) 94.99%
  );
  color: #fff;
  border-radius: 30px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  border-radius: 30px;
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.08) inset;
}

.btn-tab {
  background: linear-gradient(
    94.82deg,
    rgba(17, 137, 153, 0.94) 7.33%,
    rgba(32, 200, 184, 0.94) 94.99%
  );
  color: #fff;
  min-width: 140px;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.08) inset;
}
.btn-tab2 {
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.08) inset;
  color: #fff;
  min-width: 140px;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.btn-tab2:hover {
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.08) inset;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.tab-icon {
  width: 20px;
  height: 20px;
}
/* Mobile View (Max Width 768px) */
@media (max-width: 768px) {
  .mobile-center {
   justify-content: center!important;
  }
}
