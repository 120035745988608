/* src/views/channels/Channels.css */
.channel-page {
    padding:  0px;
  }
  .channel-page p {
    font-size: 16px;
  }
  .channel-page ul li{
    list-style: none;
    padding: 7px 0px;
  }
  .channel-page ul{
    padding-left: 0;
  }.title_small {
    font-size: 12px;
    letter-spacing: .21em;
    padding: 5px 10px;
    background-color: #f7f4ffbd;
    border-radius: 5px;
    color: #5dc4eb;
    margin-bottom: 20px;
}.integration-logo {
  width: 100px;
  height: 100px;
  border: 1px solid var(--light-grey);
  background-color: #fff;
  border-radius: 10px;
  margin-top: 0;
  padding: 20px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, .05), 0 3px 7px rgba(0, 0, 0, .1);
}.choose-us.style-9 .nav {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between!important; */

}
.choose-us.style-9 .nav .nav-link img {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}
.choose-us.style-9 .nav .nav-link {
  color: #00bdff;

  text-transform: capitalize;
  padding: 20px 1px;
  border-radius: 0;
}.choose-us.style-9 .nav .nav-link.active {
  background: 0 0;
  border-bottom: 1px solid #00bdff;
}
.channel-page ul {
  padding-left: 0!important;
}
.choose-us.style-9  .button:hover {
  background-color: transparent;
}
 .apipage ul li{
  list-style: none;
  padding: 4px 0px;
  font-size: 14px!important;  
}