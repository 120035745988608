/* AffiliateProgram.css */

.affiliate-container {
    padding: 20px;
    background-color: #f4f4f4;
}

.partner_heading {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;
}

.affiliate-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
}

.affiliate-content {
    font-size: 16px;
    color: #666;
}

.affiliate-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
}

.affiliate-button:hover {
    background-color: #0056b3;
}
/* Accordion Container */
/* Accordion Container */
.accordion {
    text-align: left;
    border-radius: 10px; /* Rounded corners for the entire accordion */
    overflow: hidden; /* Ensures content stays within rounded corners */
  }
  
  /* Accordion Item */
  .accordion-item {
    border-top: 1px solid #ccc; /* Light gray border for separation */
    border: none;
  }
  
  /* Accordion Button */
  .accordion-button {
    border-bottom: 2px solid #ccc; /* Gray border for the button */
    border-top: none;
    color: #54cdeb; /* Dark gray text color */
    border-radius: 10px; /* Matches the rounded corners of the accordion */
    padding: 10px 15px; /* Adds padding inside the button */
    text-align: left; /* Aligns text to the left */

    font-weight: 600; /* Makes the text bold */
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: border-color 0.3s, color 0.3s; /* Smooth transition for border color and text color */
    outline: none; /* Removes outline for better appearance */
    background: transparent; /* No background color */
  }
  
  /* Accordion Button Hover */
  .accordion-button:hover {
    border-color: #999; /* Slightly darker gray border on hover */
    color: #000; /* Darker text color on hover */
    background-color: none!important;
  }
  
  /* Accordion Collapse */
  .accordion-collapse p {
    padding: 5px 0px; /* Adds padding inside the content area */
    font-size: 15px!important; /* Sets a readable font size */
    color: #333; /* Dark text color for content */
  }
  
 .accordion-button:not(.collapsed) {
    color: none;
    background-color: none;
  }
  .accordion-button:not(.collapsed) {
    color: #54cdeb;
    background-color: transparent;
    box-shadow: none;
}
.accordion .button:hover {
  background-color: transparent!important;
}