.top-navbar {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: linear-gradient(85.7deg, #e6f3ff 1.71%, #fff8e0 99.56%);
    font-size: 12px;
    font-weight: 600;
    padding: 12px 17px;
}
.top-navbar a {
    padding: 10px 17px;
    color: #000;
    border-bottom: 2px solid transparent;
    transition: .3s;
    font-weight: 500;
}
.top-navbar a:hover {
    border-bottom-color: #5dc4eb !important;
}/* CSS for sticky navbar */

  

  
  @keyframes slide-vertical {
    0% {
      transform: translateY(100%);
    }
    20% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  .diwali-strip {
    background-color: #ff5722; /* Diwali-themed color */
    color: white;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    position: relative;
    top: 0;
    width: 100%;
    position: sticky;
    z-index: 1000; /* Ensure it stays on top */
  }
  