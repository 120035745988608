/* Footer.css */

footer {
  background-color: #f3fbff;
  background: linear-gradient(85.7deg, #e6f3ff 1.71%, #e8f5fb31 99.56%);
  /* background: linear-gradient(85.7deg, #e6f3ff 1.71%, #fff8e0 99.56%); */
  color: #000;
}
footer a {
  color: #000;
  margin: 6px 0;
}
.foot-links {
  margin-bottom: 20px;
}

.links-item h6 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.links-item ul {
  list-style: none;
  padding: 0;
}

.links-item ul li {
  margin-bottom: 10px;
}

.links-item ul li a {
  color: #000;
  margin: 6px 0;
  text-decoration: none;
}

.links-item ul li a:hover {
  color: #54cdeb;
}

.social-icons .social-icon {
  width: 37px;
  border-radius: 10px;
  margin-inline-end: 5px;
}

.social-icons a {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .foot-links .row {
    flex-direction: column;
  }

  .foot-links .col-lg-3 {
    margin-bottom: 20px;
  }
  .social-icons{
    justify-content: center;
  }
}

.border-top {
  border-top: 1px solid #444;
}

.footerline:before {
  background: linear-gradient(
    90deg,
    rgba(225, 223, 237, 0),
    #00bdff6b 50%,
    rgba(225, 223, 237, 0)
  );
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;

  width: 100%;
}

small {
  color: #666;
}

a {
  text-decoration: none;
}

.footerline a {
  text-decoration: none;
  color: #666;
}

.links-item ul li {
  margin-bottom: 10px;
}

.social-icons a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-logo img {
  width: 100%;
}
.custom-footer{
  background: rgba(7, 37, 65, 1);
  color: #fff;

}
.custom-footer li a{
 color: #fff!important;
 font-size: 15px;
}

.social-icon-link {
  background: rgba(12, 56, 104, 1); /* Background color */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Circle's width */
  height: 40px; /* Circle's height */
  border-radius: 50%; /* Makes the element circular */
  color: #fff; /* Icon color */
  font-size: 20px; /* Icon size */
  transition: all 0.3s ease; /* Smooth hover effect */
  text-decoration: none; /* Removes underline from links */
}

.social-icon-link:hover {
  background: rgba(0, 123, 255, 1); /* Hover background color */
  color: #fff; /* Hover icon color */
}
