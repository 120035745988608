/* BlogPage.css */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 130px; /* Adjust to the height of your header */
}
.summary-card {
  border: 1px solid rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  padding: 20px 40px;
  position: sticky !important;
  top: 120px;
}

.blog-page ul li {
  text-align: left;
}

.summary-card h3 {
  font-size: 20px;
  font-weight: 700;
}

.summary-card ul li {
  padding-left: 0;
  font-size: 14px;
  color: #999;
  text-align: left;
  margin: 15px 0;
}
.summary-card ul li a {
  color: #999;
}

.brd-gray {
  border-color: rgba(0, 0, 0, 0.1333333333) !important;
}

.blogBox p {
  text-align: justify;
}

.blogBox h4 {
  text-align: left;
}

.blogBox h3 {
  text-align: left;
}

.entry-content {
  line-height: 1.6;
}

.entry-content p {
  margin-bottom: 1rem;
  color: #000;
}

html {
  scroll-behavior: smooth;
}
ol {
  color: #000;
}
.policy-link {
  font-size: 16px;
  color: #007bff !important; /* Link color */
  margin: 0px 6px;
  text-decoration: none; /* Remove underline */
  font-weight: bold !important; /* Bold text */
  border-bottom: 2px solid #007bff; /* Underline effect */
  transition: color 0.3s, border-color 0.3s, transform 0.3s; /* Smooth transition */
  display: inline-block; /* Ensure scaling works properly */
}
.policy-link:hover {
  color: #0056b3; /* Darker color on hover */
  border-bottom: 2px solid #007bff; /* Change underline color on hover */
  transform: scale(1.03); /* Scale up for zoom-in effect */
}
