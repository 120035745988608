/* RedirectToExternal.css */
.redirect-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light gray */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .redirect-message {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
  }
  