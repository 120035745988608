.bg-gradients{
  background: linear-gradient(90deg, rgba(14, 145, 154, 0) 6.5%, #10AAB5 51.5%, rgba(7, 74, 79, 0) 89.5%);
  color: #fff;
  font-weight: 500;
  padding: 15px 0px;
}
.gradient_1{
  color: #fff;
  background: linear-gradient(85.35deg, #093055 0.77%, #12DFDF 98.65%);
}
.widget-card{
  background: linear-gradient(92.37deg, #0B4B65 1.78%, #12D7D9 94.96%);
  color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  box-shadow:rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 10px 25px;
  text-align: center;
  transform: none;
  transition: all .2s;
}
.update-cta{
  background: linear-gradient(92.5deg, #0A4763 -0.68%, #099E8D 97.14%);
  border-radius: 15px;
}
.in-cta{
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border-radius: 15px;
}
.btn-graident{
  color: #fff;
  font-size: 13px;
  background: linear-gradient(93.61deg, #144165 -1.93%, #22CEBA 102.71%);
}
.btn-graident:hover{
  color: #fff;
}
.btn-graident-2{
  color: rgba(15, 137, 150, 1);
  font-size: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1.5px solid  #0A9D8D;
  border-radius: 12px!important; /* Round corners with 12px radius */
}
.btn-graident-2:hover{
  color: rgba(15, 137, 150, 1);
  background: rgba(255, 255, 255, 1);
  border: 1.5px solid  #0A9D8D;
  border-radius: 12px!important; /* Round corners with 12px radius */
}
.heading-gradient {
  font-size: 1.6rem; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  background: linear-gradient(92.72deg, #144D67 24.58%, #22CEBA 102.91%);
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit-specific */
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback for browsers that do not support background-clip */
}
.linear_gradiant{
 background: linear-gradient(86.6deg, #0D7E92 19.86%, #12D5D7 115.07%);
}
.bg-gradient_heading {
  background: linear-gradient(90.26deg, #133D63 39.03%, #18AF80 55.56%);
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* WebKit-specific */
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 28.03px !important;
  line-height: 22px;
  text-align: center;
  padding: 8px 36px; /* Fixed missing semicolon */
}

.stepimage{
  background: rgba(255, 255, 255, 0.05);
  border-radius: 13px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.15) inset;
  padding: 11px;
  box-shadow: -1px -1px 4px 1px rgba(13, 18, 44, 0.11);
  width: 60px;
  
}
.whatsapp_heading{
  color: rgba(8, 44, 77, 1);
  font-weight: 700!important;
}

.feature-tick-icon{
  width: 15px;
}
