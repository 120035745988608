.about-page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-page p {
  font-size: 1rem;
}

.features_inner {
  position: relative;
  text-align: justify;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px 0 20px 0;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 40px rgba(100, 116, 139, .08), 0 0 14.6007px rgba(100, 116, 139, .0552), 0 0 7.08835px rgba(100, 116, 139, .0445), 0 0 3.47484px rgba(100, 116, 139, .0355), 0 0 1.37396px rgba(100, 116, 139, .0248);
  border: 1px solid #fff;
  border-left: 6px solid #00a0ff;

}

.testimonials.style-1 .content .info {
  position: relative;
  padding-left: 75px;
  min-height: 100%;
}

.testimonials.style-1 .content .info::after {
  position: absolute;
  content: "";
  left: 25px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: rgba(153, 153, 153, .0666666667);
}

.about_Card {
  background: linear-gradient(to left bottom, #fff, #fff);
  border-radius: 30px;
  box-shadow: 0 0 10px 0 rgba(33, 35, 34, .1);
  border-left: 6px solid #00a0ff;
}

.about_Card p {
  font-size: 18px;
  text-align: left;
}

.about.project-card.style-7 {
  background: linear-gradient(to left bottom, #f3fbff, #f3fbff);
  overflow: hidden;
  display: block;
}.about.projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link.active, .about.projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link:hover {
  text-decoration: none;
  margin-left: 0;
  background: linear-gradient(to left bottom, #fff, #ecf1f7);
  border-radius: 13px;
  border: 1px solid #5dc4eb;
}
.projects.style-7 .projects-tabs .nav-pills .nav-item .nav-link {
  font-size: 16px;
  border-radius: 0;
  background: 0 0;
  color: #000;
  margin: 5px 0;
  text-transform: capitalize;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #54cdeb;
}