/* src/components/FAQSection.css */
.faq-section {
  padding: 10px 44px;
  box-shadow: 0px 0px 20px 6px #e0dfdf59;
  border-radius: 20px;
}

.faq-item {
  margin-bottom: 15px;
}

.faq-question {
  width: 100%;
  font-size: 18px;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  position: relative;
  transition: max-height 0.3s ease, padding 0.3s ease;
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
  padding: 1rem;
  width: 100%;

  border: 1px solid #ddd;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.faq-toggle-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.faq-answer {
  /* max-height: 0; */
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  text-align: left;
  font-size: 1rem!important;
  padding: 0rem 1rem;
}

.faq-answer.show {
  max-height: 1000px; /* Arbitrary large value to accommodate content */
  color: #666;
  padding: 0px 12px;
  font-size: 15px;
  padding: 10px 20px!important;
}
.faq-answer ol {
  color: #666;
  font-size: 15px;
}
/* // CSS file (FaqSection.css) */

.faq-unique-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.faq-unique-section .faq-item {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 24.56px 32.74px -14.73px rgba(149, 149, 149, 0.25);

  /* border: 1px solid #ddd; */
  border-radius: 0px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
}

.faq-unique-section .faq-question {
  font-weight: bold;
  color: #333;
  font-size: 16px;
  background-color: #fff;
  border: none;
  padding: 0px 20px;
}

.faq-unique-section .faq-answer {
  margin-top: 0px;
  color: #555;
  padding: 0px 20px;
}

.faq-unique-section .faq-item.active {
  border-color: #22CEBA;
}

.faq-unique-section .faq-item.active .faq-question {
  color: rgba(27, 17, 57, 1);
  font-size: 16px;
  background-color: #fff;
  border: none;
  padding: 0px 20px;
}

.faq-toggle-btn {
  font-size: 1.5rem; /* Increase size of the + / - button */
  font-weight: 300;
  /* margin-right: 10px; */
  cursor: pointer;
}