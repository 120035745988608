.carousel-container {
  width: 100%;
  padding: 20px 0;
  background-color: #fff;
  overflow: hidden;
}
.carousel-item {
  width: 100%;
  display: flex !important; /* Inline-block hatane ke liye */
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}

.carousel-img {
  width: 30px; /* Icon ka size adjust karein */
  height: 30px;
  object-fit: contain;
  margin-right: 4px;
}

.carousel-link {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  white-space: nowrap; /* Text ko ek hi line mein rakhne ke liye */
}

.carousel-link:hover {
  color: #007bff;
}
