.whatsapp-pricing-page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.whatsapp-pricing-page p {
  font-size: 1rem;
}
.pricing.style-8 .pricing-body .pricing-card .price ul {
  padding-left: 0;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check,
.pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check {
  margin: 0;
  padding: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.pricing.style-8
  .pricing-head
  .pricing-tabsHead
  .price-radios
  .form-check
  .form-check-input:checked
  ~ .form-check-label {
  background-color: #bef2fc;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.0666666667);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.0666666667);
}

.pricing.style-8
  .pricing-head
  .pricing-tabsHead
  .price-radios
  .form-check
  .form-check-label {
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.blog.style-14 .row .col-lg-4:last-of-type .blog-item::after,
.navbar.style-6 .nav-item:last-of-type .nav-link::after,
.pricing.style-2 .monthly_price,
.pricing.style-2 .yearly_price,
.pricing.style-5 .monthly_price,
.pricing.style-5 .pricing-tabsHead .price-radios .form-check .form-check-input,
.pricing.style-8
  .pricing-head
  .pricing-tabsHead
  .price-radios
  .form-check
  .form-check-input,
.product-page
  .product-info
  .color-quantity
  .select-color
  .colors-content
  .form-check
  .form-check-input,
.projects.style-7 .projects-tabs .swiper-button-next::after,
.projects.style-7 .projects-tabs .swiper-button-prev::after,
.projects.style-8 .section-head .swiper-button-next::after,
.projects.style-8 .section-head .swiper-button-prev::after,
.shop-page .filter .filter-card .category-checkRadio .form-check-input,
.yearly-content {
  display: none;
}

.pricing.style-8 .pricing-body .pricing-card {
  position: relative;
  padding: 2px 10px;
  /* margin: 0px 20px; */
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: -2px -4px 12.4px 3px rgba(0, 0, 0, 0.05);
  border: 1.5px solid #18af80;
  /* z-index: 5; */
  text-align: start;
  list-style-type: none;
}

.pricing.style-8 .pricing-body .pricing-card ul {
  list-style-type: none;
  padding-left: 0;
}

.about.style-1 .content .about-numbers,
.choose-us.style-2 .choose-numbers,
.contact.style-1 .container,
.contact.style-14 .contact-card .row,
.contact.style-6 .content .form,
.container,
.container-fluid,
.single-project.style-5 .about-app.style-5 .img-content .img .main-img,
.testimonials.style-1 .content,
.testimonials.style-6 .img img,
header.style-2 .content {
  position: relative;
}

.pricing.style-8 .pricing-body .content {
  padding-bottom: 100px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.pricing.style-8 .pricing-body .pricing-card .pricing-title p {
  font-size: 16px;
  text-align: center;
  color: #666;
}

.pricing.style-8 .pricing-body .content {
  padding-bottom: 100px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.about-page header.style-5,
.about-page.style-2 .about.style-2,
.about-page.style-2 .timeline,
.about-page.style-2 .timeline .timeline-content,
.about.style-1,
.about.style-10,
.about.style-14 .img,
.about.style-3,
.about.style-4 .integration,
.about.style-5 .content .img.main-img3,
.about.style-6,
.about.style-7,
.blog-page.style-5 .related-postes-slider .swiper-slide-prev,
.blog.style-1 .content .blog_slider,
.career-details-page.style-5 .career-form .form .form-group,
.career-details-page.style-5 .careers-positions-slider5,
.careers-page.style-5 .career-form .form .form-group,
.choose-us.style-1,
.choose-us.style-6,
.choose-us.style-7 .img,
.community.style-12,
.connect-with .contact-row .connect-box p.border-before,
.contact.style-13,
.contact.style-14,
.download.style-5 .content h2 span,
.faq.style-10 .integration-card,
.faq.style-15,
.features.style-11 .features-cards,
.navbar.style-12 .nav-item,
.navbar.style-12 .nav-search .form-group,
.numbers.style-8 .numbers-btm,
.portfolio.style-1,
.pricing.style-3,
.pricing.style-8 .pricing-body,
.projects.style-13,
.projects.style-7 .projects-tabs,
.projects.style-8 .section-head,
.reviews.style-2,
.screenshots.style-11 .links .img,
.screenshots.style-11 .main-img,
.screenshots.style-14 .img,
.services-details-page.style-5 .contact.style-5,
.services.style-1,
.services.style-10,
.services.style-7 .services-content,
.services.style-9 .services-content,
.signup.style-11,
.single-project.style-5 .about-app.style-5 .img-content .img,
.single-project.style-5 .screenshots,
.slider-style-6,
.team.style-1,
.testimonials.style-11 .testimonials-slider11,
.testimonials.style-3 .testimonial-slider.style-3,
.timeline.style-15 .timeline-cards,
footer.style-11 .foot-links,
footer.style-14 .payment-cards,
footer.style-6 .form .form-group,
footer.style-8,
footer.style-8 .content .links-content .foot-subscribe .form-group,
header.style-1,
header.style-13 .img,
header.style-3 .content .info .form-group.input-with-icon,
header.style-5 .info h1 span,
header.style-7 .img,
header.style-8 .info .form .form-group {
  position: relative;
}
.sales {
  background: #fff;
  border: 2px solid #bef2fc;
  padding: 30px;
  border-radius: 20px;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.pricing.style-8 .pricing-body .pricing-card .pricing-info li .icon {
  margin-right: 17px;
}
.pricing.style-8 .pricing-body .pricing-card .pricing-info li {
  font-size: 10px;
  color: rgba(43, 42, 42, 1);
  font-weight: 500;
  margin: 10px 0 0;
}

.pricing.style-8 .pricing-body .pricing-card .price .price-btn i {
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.pricing.style-8 .pricing-body .pricing-card .pricing-info li .icon {
  margin-right: 17px;
}

.pricing.style-9 .pricing-card {
  padding: 50px;
  background-color: #fff;
  border-radius: 6px;
}

.pricing.style-5 .pricing-tabsHead .price-radios .form-check,
.pricing.style-8 .pricing-head .pricing-tabsHead .price-radios .form-check {
  margin: 0;
  padding: 0;
}
.section_bx3 {
  width: 100%;
  background: #f2f7f2;
  padding: 30px 0px 40px;
}
.wp-pricing-card {
  background: #fff;
  height: 100%;
  padding: 30px;
  margin: 0.5%;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 16%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.section-head.style-8 h3 {
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 600;
}
.card_statices {
  background-color: #fff;
  text-align: center;

  height: 100%;
  padding: 22px 10px;
  border-radius: 10px;
  border-top: 3px solid #5dc4eb;
  transition: 0.5s;
}
.logo-container {
  text-align: center;
  flex-grow: 1;
  max-width: 150px;
}
.anantya_logo {
  max-width: 100%;
  height: auto;
}
.get_demo {
  padding: 37px 0px;
  background: linear-gradient(to top, #fff 0, #edf4fe 74%, #e7f1ff 100%)
    no-repeat 0px 0px;
  border-radius: 19px;
  background-size: 100%;
}
.pricing.style-8 .pricing-body .pricing-card .price p {
  font-size: 16px;
  color: #0066ff;
  font-weight: 700;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.pricing.style-8 .pricing-head {
  /* padding: 100px 0 70px; */
  /* background-color: #b5e3f3; */
  padding: 0px 0 0px 0px;
}

.pricing.style-8 .pricing-head .ui-widget-content {
  border-radius: 15px !important;
  height: 5px;
  border: 0;
  background-color: #151515;
  position: relative;
}

.pricing.style-8 .pricing-head .ui-button,
.pricing.style-8 .pricing-head .ui-state-default,
.pricing.style-8 .pricing-head .ui-widget-content .ui-state-default,
.pricing.style-8 .pricing-head .ui-widget-header .ui-state-default,
.pricing.style-8 .pricing-head html .ui-button.ui-state-disabled:active,
.pricing.style-8 .pricing-head html .ui-button.ui-state-disabled:hover {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 0;
  top: -6px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.pricing.style-8 .pricing-head .ui-slider-horizontal .ui-slider-range-min {
  background-color: var(--color-main);
}

.pricing.style-8 .pricing-head .users-number {
  color: #606060;
  display: inline-block;
  position: absolute;
  left: 0;
}

.pricing.style-8 .pricing-head .users-number input {
  background: 0 0;
  border: 0;
  width: 35px !important;
  color: #fff;
  margin-top: 15px;
  min-width: none;
  font-weight: 700;
}

.pricing.style-8 .pricing-head .pricing-tabsHead .price-radios {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  /* background-color: #9ecbff; */
  box-shadow: 1px 5px 6.1px 0px rgba(0, 0, 0, 0.1);
  background: rgba(20, 77, 103, 1);
  color: #fff;

  padding: 5px 5px 1px 5px;
  border-radius: 50px;
}

.pricing.style-8
  .pricing-head
  .pricing-tabsHead
  .price-radios
  .form-check
  .form-check-label {
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.pricing.style-8
  .pricing-head
  .pricing-tabsHead
  .price-radios
  .form-check
  .form-check-input:checked
  ~ .form-check-label {
  background-color: #fff;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.0666666667);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.0666666667);
  color: rgba(7, 37, 65, 1);
}

.pricing.style-8 .pricing-body .content {
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.pricing.style-8 .pricing-body .popular-head {
  position: absolute;
  height: 38px;
  width: 160px;
  padding-top: 10px;
  font-size: 12px !important;
  margin: 0 auto;
  border-radius: 30px;
  background: rgba(235, 93, 136, 1);
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.17);
  color: #fff;
  text-transform: uppercase;
  display: flex; /* Align items horizontally and vertically */
  align-items: center;
  justify-content: center;
  z-index: 2; /* Ensure this appears above the ::after background */
  /* Center horizontally and vertically */
  left: 50%;
  transform: translate(-50%, -50%);
}
.pricing.style-8 .pricing-body::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  /* background-color: #b5e3f3; */
  z-index: 1; /* Set z-index for the background layer */
}

.pricing.style-8 .pricing-body .pricing-card:hover .price .price-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
}

.pricing.style-8 .pricing-body .pricing-card:hover .price p {
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  opacity: 1;
}

.pricing.style-8 .pricing-body .pricing-card .pricing-title,
footer.style-14 .foot-info .foot-logo {
  margin-bottom: 10px;
}

.pricing.style-8 .pricing-body .pricing-card .pricing-title h2 {
  /* font-weight: 700; */
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin: 30px 0px 0px 0px;
  text-transform: capitalize;
}

.pricing.style-8 .pricing-body .pricing-card .pricing-title p {
  font-size: 16px;
  text-align: center;
  color: #666;
}

.pricing.style-8 .pricing-body .pricing-card .price p {
  font-size: 16px;
  color: var(--color-main);
  font-weight: 700;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 0rem;
}

.pricing.style-8 .pricing-body .pricing-card .price .price-btn i {
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.pricing.style-8 .pricing-body .pricing-card .pricing-info li .icon {
  margin-right: 10px;
}

.pricing.style-9 .pricing-card {
  padding: 50px;
  background-color: #fff;
  border-radius: 6px;
}
.ad {
  background-color: #fbfbfc;
}
.features-cards.style-5.box-1 {
  display: block;
  border: 1px solid #fdf5e6;
  border-radius: 16px;
  padding: 0px 10px;
  padding-bottom: 10px;
  background: linear-gradient(180deg, #fdf5e6, #fff 40%, #fff);
}
.features-cards.style-5.box-1 p {
  font-size: 14px;
  padding: 10px;
  text-align: left;
}
.features-cards.style-5.box-1 .card-title {
  font-size: 14px;
  padding: 0px 6px;
  text-align: left;
} /* Button styling */

@media (max-width: 768px) {
  .logos img {
    width: 100px !important;
  }
} /* Hide on screens smaller than 992px */
@media (max-width: 991px) {
  .popular-head {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .second-row {
    padding-top: 60px;
  }
  .third-row {
    padding-top: 60px;
  }
}

.card_price_1 {
  background: rgba(169, 239, 182, 1);
  box-shadow: -1px -1px 7.1px 2px rgba(34, 206, 186, 0.2);
  border-radius: 15px;
  text-align: center;
  padding: 18px 22px;
}
.card_price_1 .card-title {
  font-size: 11px;
}
.card_price_2 .card-title {
  font-size: 11px;
}
.card_price_3 .card-title {
  font-size: 11px;
}
.card_price_4 .card-title {
  font-size: 11px;
}
.card_price_1 h5 {
  font-size: 14px;
  font-weight: 600;
}
.card_price_2 h5 {
  font-size: 14px;
  font-weight: 600;
}
.card_price_3 h5 {
  font-size: 14px;
  font-weight: 600;
}
.card_price_4 h5 {
  font-size: 14px;
  font-weight: 600;
}
.card_price_2 {
  background: rgba(171, 233, 244, 1);
  box-shadow: -1px -1px 7.1px 2px rgba(34, 206, 186, 0.2);
  border-radius: 15px;
  text-align: center;
  padding: 18px 22px;
}
.card_price_3 {
  background: rgba(112, 242, 227, 1);
  box-shadow: -1px -1px 7.1px 2px rgba(34, 206, 186, 0.2);
  border-radius: 15px;
  text-align: center;
  padding: 18px 22px;
}
.card_price_4 {
  background: rgba(243, 231, 191, 1);
  box-shadow: -1px -1px 7.1px 2px rgba(34, 206, 186, 0.2);
  border-radius: 15px;
  text-align: center;
  padding: 18px 22px;
}
.get-widget-btn {
  border: 2px solid rgba(255, 255, 255, 1);
  background: transparent;
  padding: 5px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  border-radius: 12px;
  box-shadow: -1px -1px 7.1px 2px rgba(34, 206, 186, 0.1);
}
.form-containers {
  max-width: 700px;
  margin: 0 auto;
}

.form-containers .form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 1rem;
}

.form-containers .form-control {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(36, 37, 37, 0.6);
  min-height: 44px;
  background-color: #f5f5f5;
}

.form-containers .phone-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.form-containers .country-code {
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  background-color: #f5f5f5;
  min-height: 44px;
}

.form-containers .phone-container .form-control {
  border-radius: 0 5px 5px 0;
  flex: 1;
}

.form-containers .form-check {
  margin: 1rem 0;
}

.form-containers .recaptcha-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 1.5rem 0;
}

.form-containers .recaptcha-box {
  border: 1.5px solid rgba(79, 205, 190, 1);
  box-shadow: 0px -1px 7.3px 0px rgba(0, 0, 0, 0.17);
  min-width: 220px;
  padding-left: 20px;
  height: 40px;
  border-radius: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  color: #666;
}

.form-containers .btn-submit {
  display: block;
  width: 100%;
  padding: 12px;
  font-weight: 600;
  font-size: 1rem;
  color: #fff;
  box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.1);
  background: linear-gradient(92.29deg, #22ceba -0.18%, #21496d 110.09%);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
}

.form-containers .btn-submit:hover {
  opacity: 0.9;
}
.form-containers h5 {
  font-size: 22px;
  margin-bottom: 15px !important;
}

.featured_pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0px 10px;
  background: linear-gradient(92.5deg, #0a4763 -0.68%, #099e8d 97.14%);
}
.featured-card {
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  /* margin: 20px 0px; */
  width: 192px;
  padding: 16px 16px 0px 16px;
  text-align: center;
  color: white;
  border: 1.6px solid rgba(255, 255, 255, 0.15);
  transition: transform 0.3s ease;
}

/* .featured-card:hover {
  transform: scale(1.02);
  border: none;
} */

.featured-icon {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.15) inset;
  height: 59px !important;
  width: 59px !important;
  border-radius: 50%;
  margin-bottom: 10px;
}
.featured-title {
  text-align: left;
  font-weight: 400;
  font-size: 15px !important;
}

@media (max-width: 768px) {
  .featured-card {
    width: 120px;
    padding: 8px;
  }

  .featured-icon {
    font-size: 2rem;
  }

  .featured-title {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .featured-card {
    width: 100%;
    border-radius: 15px;
    padding: 15px;
  }

  .featured-icon {
    font-size: 1.5rem;
  }

  .featured-title {
    font-size: 0.8rem;
  }
}
.demo_button {
  box-shadow: 0px 1px 5.3px 0px rgba(34, 206, 186, 0.39);
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 15px;
  font-size: 14px;
}
.demo_button:hover {
  box-shadow: 0px 1px 5.3px 0px rgba(34, 206, 186, 0.39);
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 15px;
  font-size: 14px;
}
.pricing_page_header {
  background: linear-gradient(to bottom, #001a33, #003d5c);
  box-shadow: 20px 20px 50px 0px rgba(25, 61, 131, 0.05);
  backdrop-filter: blur(12px);
  padding: 7px 0px;
}
.pricing-card .pricing-title h1 {
  text-align: center;
  font-size: 1.6rem;
  margin-top: 20px;
}
del {
  color: rgba(214, 214, 214, 1);
  font-size: 14px;
}
.card_image_Connect {
  width: 40%;
  padding: 10px 0px;
}
.popular-head p {
  font-size: 13px;
}
.bg-gradient_headings {
  background: linear-gradient(90.26deg, #133d63 39.03%, #18af80 55.56%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18.0303px !important;
  line-height: 22px;
  text-align: center;
  padding: 8px 36px;
}
.price-btn {
  padding: 10px 40px;
  margin: 0px auto;
  border-radius: 50px;
  background: linear-gradient(95.91deg, #144165 -15.83%, #22ceba 112.38%);
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.17);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pricing-card small {
  text-align: center;
  font-size: 12px;
  margin: 0 auto;
  display: block; /* Ensures it behaves like a block-level element */
  width: fit-content; /* Ensures only the text width is used */
}
.visibility-hidden {
  visibility: hidden;
}
.bg-square{
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
}
.lighter-text{
  font-weight: 400;
  color: rgba(29, 27, 27, 1);
  font-size: 14px!important;
  margin-top: 0!important;

}
.mobi-section {
  display: none; /* Default: hide on larger screens */
}

@media (max-width: 768px) {
  .mobi-section {
    display: block; /* Show only on mobile devices */
  }
}
.desk-section {
  display: block; /* Show only on mobile devices */

}

@media (max-width: 768px) {
  .desk-section {
    display: none; /* Default: hide on larger screens */
  
  }
}
