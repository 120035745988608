.internalpage_link {
    font-size: 15px;
    font-weight: 500;
    border-radius: 0;
    background: 0 0;
    margin: 5px 0 5px 5px;
    text-transform: capitalize;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.internalpage_link i,
.scroll_unique .internalpage_link i,
.scroll_unique_2 .internalpage_link i {
    opacity: 1;
    transition: opacity .3s;
}

.intro_1,
.scroll_unique .color_1,
.scroll_unique .colorfa_1,
.scroll_unique_2 .color_1,
.scroll_unique_2 .colorfa_1 {
    color: #00bdff;
}

.internalpage_link:hover,
.scroll_unique .internalpage_link:hover,
.scroll_unique_2 .internalpage_link:hover {
    text-decoration: underline;
    margin-left: 20px;
}

.scrollAnimationSection_1 {
    background-color: #e8f5fb;
    color: #00bdff;
}

.scrollAnimationSection_2 {
    background-color: #fdf5e6;
    color: #ecaf3d;
}

.scrollAnimationSection_3 {
    background-color: #fbe8da;
    color: #f2ba95;
}

.scrollAnimationSection_4 {
    color: #54b9a6;
    background-color: #dbf3ef;
}

.scrollAnimationSection_5 {
    background-color: #ffeff2;
    color: #ffb8bf;
}

.scrollAnimationSection_1,
.scrollAnimationSection_2,
.scrollAnimationSection_3,
.scrollAnimationSection_4,
.scrollAnimationSection_5 {
    width: max-content;
    border-radius: 2px;
    line-height: 15px;
    letter-spacing: .21em;
    padding: 2px 8px;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

/* src/components/IncrediblePlatformCapabilities.css */


.color_1 .colorfa_1 {
    color: #00bdff;
}

.color_1 {
    color: #00bdff;
}

.color_2,
.colorfa_2 {
    color: #ecaf3d;
}

/* Adjust other styles as needed */
.additional-element {
    position: relative;
    margin: 0px 0;
    /* Adjust margin as needed */
    text-align: center;
    /* Center the content */
}



.color_1 {
    color: #00bdff;
    /* Color for 01 */
}

.color_2 {
    color: #ecaf3d;
    /* Color for 02 */
}

.color_3 {
    color: #f2ba95;
    /* Color for 03 */
}

.color_4 {
    color: #FF33A8;
    /* Color for 04 */
}

.color_5 {
    color: #ffb8bf;
    /* Color for 05 */
}



.progress-container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: whitesmoke;
    /* Border color */
    height: 4px;
    z-index: 1000;
}

.progress-bar {
    height: 100%;
    background-color: #54cdeb;
    /* Progress bar color */
    transition: width 0.1s linear;
}


.section-text {
    padding: 0vw 6vw;
}

.additional-element {
    position: absolute;
    top: 0%;
    left: 2%;
    height: 80vh;
    width: 1.5px;
    background: linear-gradient(180deg, hsla(0, 0%, 80%, 0.802), hsla(156, 2%, 43%, 0));
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    transform: translateX(50%);
}

.circle {
    position: absolute;
    background-color: #f1f1f1;
    /* Default background color */
    transform: translateY(-50%);
    width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 38px;
}

.circle-1 {
    background: #e8f5fb;
     color:#00bdff;
}

.circle-2 {
    background: #fdf5e6;
    color: #ecaf3d;
}

.circle-3 {
    background: #fbe8da;
    color: #f2ba95;
}

.circle-4 {
    background: #dbf3ef;
    color: #54b9a6;
}

.circle-5 {
    background: #ffeff2;
    color: #ffb8bf;
}