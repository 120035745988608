.unique-tab-section {
    display: flex;
    flex-direction: column;
    background: linear-gradient(93.74deg, #E6F3FF 20.05%, rgba(255, 255, 255, 0) 112.5%);
    align-items: center;
    justify-content: center;
  }
  
  .unique-tab-section
  .tabs {
    display: flex;
    /* gap: 10px; */
    margin-bottom: 20px;
    background: rgba(154, 203, 198, 1); /* Striped background */
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0 auto;
    border-radius: 50px;
    width: fit-content; 
    margin: 0 auto; /* Center align */
    
  }
  .unique-tab-section
  .tab-button {
    padding: 15px 45px;
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 15px;
    color: rgba(49, 52, 53, 1);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
  }.unique-tab-section .tab-button:hover{
    background: rgba(21, 97, 108, 1);
    color: white;
    font-weight: 500;
    font-size: 15px;
    box-shadow: 3px -1px 4px -1px rgba(38, 189, 211, 0.4);
  }
 
  .tab-button.active {
    background: rgba(21, 97, 108, 1); /* Active tab background */
    color: white;
    font-weight: 500;
    font-size: 15px;
    box-shadow: 3px -1px 4px -1px rgba(38, 189, 211, 0.4);
  }
  .unique-tab-section
  .tab-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }
  

  .unique-tab-section
  .content {
    max-width: 600px;
    transition: all 0.5s ease-in-out;
  }
  
  .unique-tab-section .content h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  .unique-tab-section
  .content p {
    margin-bottom: 20px;
  }
  .unique-tab-section
  .content button {
    padding: 10px 20px;
    background: linear-gradient(93.61deg, #144165 -1.93%, #22CEBA 102.71%);
    color: #fff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .unique-tab-section .tabs{
      width: 100%;
      align-items:normal;
    }
    
    .tab-button.active {
      background: rgba(21, 97, 108, 1)!important;
      color: white!important;
      font-weight: 500;
      font-size: 10px!important;
      box-shadow: 3px -1px 4px -1px rgba(38, 189, 211, 0.4);
  }
  
  .unique-tab-section .tab-button {
    padding: 2px 5px;
    background: transparent;
    border: none;
    font-size: 10px;
    font-weight: 500;
    color: rgba(49, 52, 53, 1);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    /* white-space: nowrap; */
  }
  .tab-button:hover {
    padding: 2px 5px;
    background: rgba(21, 97, 108, 1)!important;
  
  }
 }
 .gr-btn{
  background: linear-gradient(93.61deg, #144165 -1.93%, #22CEBA 102.71%)!important;
color: #fff;
 }