/* src/forms/contactform.css */
.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    text-align: justify;
     margin-bottom: 5px;
  }
  
  input, textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
  }
  .small-label {
    font-size: 12px;
    color: light;
  }
  .custom-checkbox {
    width: 16px!important; /* Define the width */
    height: 16px!important; /* Define the height */
    margin: 0; /* Remove extra margins */
    padding: 0; /* Remove padding */
  }
 
  