.hero-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background: linear-gradient(to bottom, #001a33, #003d5c);
    color: white;
  }
  .hero-section2::after {
    content: "";
    position: absolute;
    bottom: -1px; /* Adjust this value based on where the curve should start */
    left: 0;
    width: 100%;
    height: 100px; /* Adjust height as per design */
    background-size: cover; /* Ensures it covers the space */
    z-index: 1; /* Ensure it stays above the section */
    pointer-events: none; /* Optional to make it unclickable */
}
  .hero-banner {
    text-align: center;
 
  }
  
  .hero-top {
    /* display: flex; */
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .badge,
  .meta-badge {
   
    background: rgba(34, 206, 186, 0.32);
    padding: 7px 20px;
    border: 1px solid rgba(34, 206, 186, 0.43);
    border-radius: 20px;
    font-weight: 500;
    /* font-size: 13px; */
  }
  
  .hero-title {
    font-size: 3.5rem!important;
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 1.2rem!important;
    padding: 0px 250px;
    margin-bottom: 30px;
    line-height: 1.6;
    color: rgba(155, 156, 161, 1);
  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .btn-get {
    background-color: transparent;
    border: 1px solid #22CEBA;
    color: white;
    padding: 8px 15px;
    border-radius: 25px;
    font-size: 12px;
    cursor: pointer;
    transition: background 0.3s;
    /* z-index: 9999; */
  }
  
  .btn-primary:hover {
    background-color: #00897b;
  }
  
  .btn-book {
    background-color: transparent;
    border: 1px solid #22CEBA;
    color: #fff;
    padding: 8px 15px;
    border-radius: 25px;
    font-size: 12px;
    cursor: pointer;
    transition: background 0.3s;
    /* z-index: 9999; */
  }
  .hero-top small{
    font-size: 10px;
    color: #fff;
  }
  .btn-book:hover {
    background-color: #00bfa5;
    color: white;
  }
  .btn-get:hover {
    background-color: #00bfa5;
    color: white;
  }
  
  .hero-note {
    font-size: 0.9rem;
    margin: 15px 0;
    opacity: 0.8;
  }

  
  .watch-demo-link {
    color: rgba(34, 206, 186, 1);
    font-size: 16px;
    text-decoration: none;
  }
  
  .hero-demo {
    padding:  0px 100px;
    border-radius: 20px;
     text-align: center;
     width: 100%;
  }
  
  .demo-content h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .demo-content h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .demo-image {
    max-width: 85%;
  

  }
  
  @media (max-width: 768px) {
    .hero-section2 .hero-title {
      font-size: 1.2rem!important;
    }
   
    .hero-subtitle {
      font-size: 1rem;
      padding: 0;
      color: rgba(155, 156, 161, 1);

    }
  
    .btn-primary,
    .btn-secondary {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    .hero-demo {
      padding: 20px;
    
    }
  
    .demo-content h2 {
      font-size: 1.2rem;
    }
  
    .demo-content h3 {
      font-size: 1rem;
    }
    .curve_image{
      margin-top:-16px!important;
 
   }
  }
  .meta_logo{
    width: 30px;
    margin-right: 5px;
  }
  .hero-demo {
    position: relative;
    text-align: center;
  }
  
  .demo-content {
    position: relative;
    display: inline-block;
    
  }
  
  .meta-link{
    color: #fff;
    padding: 10px 20px;
  }
  .demo-video {
    max-width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .helpdesk-video-icon__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    padding: 20px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    
  }
  
  .helpdesk-video-icon__button:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
  
  .hero-section2 .hero-icon-network {
    position: relative; /* Allows control over child positioning */

   }
  
  .hero-icon-network img {
    max-width: 100%;
    height: auto;
    position: relative;
     object-fit: contain; /* Ensures proper image scaling */
  }
  
  .curve_image{
     margin-top:-60px;
  }