.use-cases__wrapper {
    display: flex;
    width: 100%;
    position: relative
  }
  
  .use-cases__left,
  .use-cases__right {
    flex: 1
  }
  
  .image-star {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 91px;
    height: 91px;
    z-index: 1
  }
  
  .image-star img {
    height: 100%;
    width: 100%;
    object-fit: contain
  }
  
  .use-cases__left {
    position: relative;
    overflow: hidden
  }
  
  .use-cases__left img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: visibility, opacity .5s
  }
  
  .use-cases__left img.active {
    position: absolute;
    bottom: 0;
    left: 50%;
    max-width: 100%;
    height: auto;
    transform: translate(-50%, 0);
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transition: visibility, opacity .5s linear;
    object-fit: cover
  }
  
  .use-cases__right {
    padding-left: 9.3vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    padding-top: 8%;
    padding-bottom: 8%
  }
  
  .use-cases__right h2 {
    margin-bottom: 40px;
    visibility: visible;
    max-height: 100%;
    opacity: 1;
    transition: visibility, opacity .5s
  }
  
  .use-cases__nav-link {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none
  }
  
  .use-cases__nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: #e1e1e1
  }
  
  .use-cases__nav-link::-webkit-scrollbar {
    display: none
  }
  
  .use-cases__navs-tab {
    display: inline-flex;
    position: relative;
    padding-left: 10px;
    padding-right: 10px
  }
  
  .use-cases__navs-tab:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px
  }
  
  .use-cases__navs-tab h6 {
    white-space: nowrap;
    margin-bottom: 20px;
    color: #acacac
  }
  
  .use-cases__navs-tab.active h6 {
    color: #000
  }
  
  .use-cases__navs-tab:not(:first-child) {
    margin-left: 18px
  }
  
  .use-cases__navs-tab.active:before {
    background-color: #fbbbe8
  }
  
  .use-cases__nav-tab-item {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    transition: visibility, max-height, opacity .5s .3s
  }
  
  .use-cases__nav-tab-item.active {
    visibility: visible;
    max-height: 100%;
    opacity: 1;
    transition: visibility, max-height 0s .3s, opacity .5s
  }
  
  .use-cases__nav-tab-item .body-std {
    margin-bottom: 36px
  }
  
  .use-cases__nav-tab-actions a:first-child {
    margin-right: 26px
  }
  
  @media (max-width:767px) {
    .use-cases__wrapper {
      flex-wrap: wrap;
      height: 100%
    }
  
    .use-cases__left,
    .use-cases__right {
      width: 100%;
      height: 100%;
      max-width: 100%;
      flex: auto
    }
  
    .use-cases__left {
      min-height: 339px
    }
  
    .use-cases__right {
      padding-top: 40px;
      padding-left: 0;
      justify-content: flex-start
    }
  
    .use-cases__nav-tab-item .body-std,
    .use-cases__right h2 {
      margin-bottom: 30px
    }
  
    .image-star {
      position: absolute;
      top: 0;
      left: unset;
      right: 25px;
      transform: translate(-10%, -50%);
      width: 55px;
      height: 55px
    }
  
    .image-star img {
      height: 100%;
      width: 100%;
      object-fit: contain
    }
  
    .use-cases__navs-tab:not(:first-child) {
      margin-left: 14px
    }
  
    .use-cases__navs-tab h6 {
      margin-bottom: 12px
    }
  
    .use-cases__navs-tab {
      padding-left: 0;
      padding-right: 0
    }
  }
  .entry-content p{
    padding: 8px 0px;
  }
  .body-std p{
    margin: 0px 0px 36px;
    text-align: center;
  }
  .use-cases-section button {
    background-color: transparent;
    

  }