.image-grid-section {
  background: rgba(255, 184, 191, 1);
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Space out images evenly */
}

.image-item {
  width: 12.5%; /* This ensures 8 images per row (100% / 8 = 12.5%) */
  margin-bottom: 15px; /* Add space between rows */
}

.image-item img {
  width: 100%; /* Make sure the image fills the container */
  height: 100%; /* Keep the aspect ratio of the image intact */

}

.image-grid-section img {
  padding: 0px 7px;
  transition: transform 0.3s ease-in-out;
}

.image-grid-section img:hover {
  transform: scale(1.05);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .image-item {
    width: 48%; /* 2 images per row */
  }

  .image-grid {
    justify-content: space-around;
  }

  .image-grid-section img {
    width: 100%;
    /* height: auto; */
  }
}

/* Tablet responsiveness */
@media (min-width: 768px) and (max-width: 1024px) {
  .image-item {
    width: 23%; /* 4 images per row */
  }
}

/* Desktop responsiveness */
@media (min-width: 1025px) {
  .image-item {
    width: 12.5%; /* 8 images per row */
  }
}
