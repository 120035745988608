/* Overlay to dim the background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Highest z-index to ensure it's above all other content */
}

/* Main popup box */
.popup-content {
  position: relative; /* Ensure it stays within the overlay */
  background: white;
  padding: 22px 30px;
  border-radius: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Smooth shadow */
  text-align: center;
  width: 90%; /* Responsive width */
  max-width: 1065px;
  animation: popup-appear 0.3s ease-in-out; /* Smooth animation */
  z-index: 10000; /* Extra safety for popup box */
}

/* Close button styling */
.close-btn {
  position: absolute;

  top: 3px;
  right: 9px;
  border: 1px solid blue;
  background: #fff; /* Red color for close button */
  /* border: 1px solid rgba(183, 171, 171, 1); */
  color: #000;
  border: none;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  font-size: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-top-right-radius: 30px;
  align-items: center;
  z-index: 10001; /* Ensure it's above popup content */
}

.close-btn:hover {
  background-color: transparent;
  color: #54cdeb;
}

/* Title styling */
.popup-content h2 {
  font-size: 24px;
  color: #333; /* Dark grey */
  margin-bottom: 10px;
}

/* Text styling */
.popup-content p {
  font-size: 16px;
  color: #666; /* Lighter grey */
  margin: 6px 0px !important;
  line-height: 1.5;
  transform: translateX(0px) !important;
}

/* Animation for popup */
@keyframes popup-appear {
  from {
    transform: scale(0.8); /* Start smaller */
    opacity: 0; /* Fully transparent */
  }
  to {
    transform: scale(1); /* Full size */
    opacity: 1; /* Fully visible */
  }
}
.popup-content label {
  font-size: 14px;
  color: #333;
}

/* Main Progress Container */
.unique-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures equal spacing */
  width: 100%;
  margin: 0px 50px -25px 35px;
  position: relative;
}

/* Progress Item (Circle + Line) */
.progress-item {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Ensures all items stretch evenly */
  position: relative;
}

/* Circle Styling */
.progress-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid rgba(183, 171, 171, 1);
  color: rgba(183, 171, 171, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  z-index: 2;
  flex-shrink: 0; /* Prevent circles from shrinking */
}

/* Completed Circle */
.progress-circle.completed {
  background-color: rgba(55, 216, 160, 1);
  color: white;
}

/* Line Between Circles */
.progress-line {
  height: 6px;
  background-color: lightgray;
  flex-grow: 1; /* Ensures line stretches between circles */
  position: relative;
  margin: 0 15px; /* Makes lines connect seamlessly */
  z-index: 1;
  border-radius: 10px; /* Rounded corner on the top-left */
}

/* Completed Line */
.progress-line.completed {
  background-color: rgba(55, 216, 160, 1);
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .popup-content {
    width: 90%;
    padding: 15px 0px;
  }
  .progress-step {
    margin-bottom: 10px;
  }
  .progress-label {
    display: none;
    margin-top: 0px;
    font-size: 10px;
    padding-left: 0px !important;
    color: #333;
  }
  .unique-progress-container {
    margin: 10px;
  }
}
.content-section {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.left-image {
  flex: 1;
}

.diy-image {
  width: 60%;
  left: 0;
  height: auto;
}

.right-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
}
.google-btn {
  border-radius: 30px !important;
  border: 1px solid rgba(32, 220, 73, 1);
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background-color 0.218s, border-color 0.218s;
  transition: background-color 0.218s, border-color 0.218s;
  justify-content: center;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  transition: background-color 0.218s;
}
.google-btn:hover {
  background: rgba(66, 133, 244, 0.1);
  border: 1px solid #dadce0;
}

.btn-submit {
  background-color: linear-gradient(94.23deg, #0f8597 -2.83%, #0a9e8d 98.65%);
  color: white;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  font-size: 15px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.btn-submit.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status-text {
  margin-top: 10px;
  color: #555;
  text-align: center;
}

.status-text span {

 margin-top: 5px;
}

@media (max-width: 768px) {
  .content-section {
    flex-direction: column;
  }

  .left-image,
  .right-form {
    width: 100%;
  }
}
.divider-container {
  display: flex;
  align-items: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  position: relative;
}

.divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: rgba(219, 219, 219, 1);
  margin: 0px 20px;
}

.divider-text {
  color: rgb(113, 128, 150);
  background: #ffffff;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 13px;
  font-weight: 500;
}

.price .form-group input {
  width: 100%;
  padding: 14px 0px 14px 22px;
}
.price .form-control {
  min-height: 40px;
}
.top-right-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.package-details {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.btn-pay {
  display: block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  float: right;
}

.btn-pay:hover {
  background-color: #0056b3;
}
.dashed-border {
  border-bottom: 2px dashed white; /* Dashed white border */
  padding: 0px 0px 5px 0px; /* Add padding inside the border */
  color: #000; /* White text color */
}
.feature-title {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}

.feature-description {
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.76);
  font-weight: 400;
}

.right-form .form-group input {
  padding: 0px 0px 0px 20px !important;
  min-height: 44px;
}
.order-summary {
  background-color: #e5a895;
  border-radius: 15px;
  box-shadow: rgba(43, 42, 42, 0.11);
}
.whatsapplogo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  box-shadow: rgba(192, 192, 192, 0.19);
  padding: 8px;
  opacity: 44%;
}
.list-group {
  margin-top: 10px; /* Add some spacing above the list */
  padding-left: 0; /* Remove default padding */
}

.list-group-item {
  border: 2px solid rgba(21, 119, 231, 1) !important; /* Remove default list item border */
  padding: 5px 15px; /* Add padding for better spacing */
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* Light background for list items */
  border-radius: 14px !important; /* Rounded corners */
  margin-bottom: 10px; /* Space between list items */
  transition: box-shadow 0.3s ease-in-out; /* Hover effect */
}
.list-group-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow on hover */
}
.pdf-box {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 7px;
  width: 100%;
  max-width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: inherit;
}
.progress-label {
  margin-top: 0px;
  font-size: 10px;
  padding-left: 10px;
  color: #333;
}
.otp_button {
  margin-left: 10px;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 14px;
  padding: 10px 20px;
  /* border-radius: 30px; */
  white-space: nowrap;
}
.otp_Section {
  display: flex;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 10px;
}
.right-form .form-group {
  margin-bottom: 10px;
}
.tick-icon {
  font-size: 14px !important;
  color: white !important; /* White tick */
}
.font {
  font-weight: 500 !important;
  color: #2e2d2d !important;
}
.skip_button_yes {
  background-color: #d9d9d9;
  padding: 5px 35px;
  border: 1px solid #21617b !important;
  border-radius: 30px !important;
}
.skip_button_no {
  background: linear-gradient(90deg, #22c9b7 0%, #215b78 100%);
  color: #fff !important;
  padding: 5px 35px;
  border: none !important;
  border-radius: 30px !important;
}
.strip {
  background: linear-gradient(90deg, #22b2aa 0%, #22677f 100%);
  padding: 15px 0px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #fff;
  margin-bottom: 0;
}
.btn-submit {
  color: #fff !important;
}
.btn-graident-pay {
  color: #fff;
  font-size: 13px;
  border-radius: 30px !important;
  background: linear-gradient(94.23deg, #0f8597 -2.83%, #0a9e8d 98.65%);
}
.btn-graident-pay:hover {
  color: #fff;
}
.btn-success {
  border-radius: 30px !important;
}
.btn-secondary {
  border-radius: 30px !important;
}
@media only screen and (max-width: 600px) {
  .custom_btn {
    width: 75% !important;
    margin-top: 20px;
  }
}
.fe-icon {
  background-color: #fff; /* White background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border: none; /* Ensure no border is applied */
  border-radius: 4px; /* Optional: Add rounded corners */
  padding: 7px; /* Optional: Add some spacing inside */
}

