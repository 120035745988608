/* src/components/GenerateWhatsAppLink.css */

.link-card{
    background: rgba(231, 231, 194, 1);
    box-shadow: -1px 0px 12.4px 2px rgba(20, 80, 105, 0.1);
    border-radius: 15px;
}
.link-badge{
width: max-content;
border-radius: 15px;
line-height: 15px;
letter-spacing: .21em;
padding: 6px 20px;
margin-bottom: 30px;
text-transform: uppercase;
font-size: 12px;
font-style: normal;
font-weight: 500;
background: rgba(232, 245, 251, 0.2);
color: #fff;
}
.link-badge-2{
 border-radius: 15px;
line-height: 15px;
/* letter-spacing: .21em; */
padding: 6px 10px;
margin-bottom: 30px;
text-transform: uppercase;
font-size: 12px;
font-style: normal;
font-weight: 500;
color: rgba(76, 142, 205, 1);
box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.15) inset;
box-shadow: -1px -1px 4px 5px rgba(12, 222, 205, 0.11);
border: 1px solid rgba(76, 142, 205, 1)
}