.work-with-us-page {

    text-align: center;
   
  }
  
  .work-with-us-page h1 {

    margin-bottom: 20px;
  }
  
  .work-with-us-page p {
    font-size: 1rem;
  }
  