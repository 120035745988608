/* Navbar Toggler Icon */
.custom_header .navbar-toggler-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3e%3cpath stroke="%23000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22" /%3e%3c/svg%3e');
  /* Ensure the correct color of the stroke */
}

/* Offcanvas Styles */
.custom_header .offcanvas {
  width: 70% !important;
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

.custom_header .offcanvas-header {
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.custom_header .offcanvas-title {
  margin-bottom: 0;
  font-size: 1.25rem;
}
 
.custom_header .offcanvas-body {
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
  /* transform: translateX(-100%); */
}

/* Navbar Styles */
.custom_header .navbar-toggler {
  color: #000;
}

/* Navbar Links Styles */
.custom_header .navbar-nav {
  padding-top: 0.5rem;
}

.custom_header .nav-item {
  position: relative;
  margin-bottom: 0.5rem;
}

.custom_header .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom_header .nav-link:hover {
  text-decoration: none;
  color: #007bff;
}

/* Submenu Styles */
.custom_header .submenu {
  display: none;
  position: absolute;
  left: -100%; /* Start hidden from the left */
  width: 100%;
  padding-left: 1rem;
  transition: left 0.3s ease-in-out;
  list-style: none;
  padding: 0;
}

.custom_header .submenu.d-block {
  left: 0; /* Slide in */
}

.custom_header .nav-next {
  cursor: pointer;
}

.custom_header .nav-next span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(310deg);
  margin-right: 10px;
  transition: transform 0.2s;
}

.custom_header .nav-next:hover span {
  transform: rotate(45deg) translateX(2px);
}

/* Button Styles */
.custom_header .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.custom_header .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Additional styles if needed for visual consistency */
.custom_header .hc-offcanvas-nav .nav-next span::before {
  transform: translate(-50%, -50%) rotate(135deg);
}

.custom_header .submenu li {
  padding: 0.5rem 0;
}
.custom_header .slide-in-left {
  animation: slide-in-left 0.3s forwards;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.offcanvas-body.submenu-open {
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}
