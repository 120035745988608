@media screen and (max-width: 991px) {
    .number-card.style-6 {
        margin: 15px 20px;
        border: 0 !important;
    }
}
@media screen and (max-width: 991px) {
    .heading{
        font-size: 30px!important;
    
    }
}.modal-backdrop {
    z-index: 10000 !important; /* Ensure backdrop is above navbar */
  }
  
  .modal {
    z-index: 10001 !important; /* Ensure modal content is above backdrop */
  }
 