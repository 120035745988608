.blog .side-posts .item .img {
    width: 200px;
    height: 139px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    -webkit-margin-end: 25px;
    margin-inline-end: 25px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.blog .side-posts .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
}

#loading-wrapper.show,
.img-contain,
.img-cover,
.monthly-content,
.pricing.style-2 .monthly_price.show,
.pricing.style-2 .yearly_price.show,
.subline {
    display: block;
}

.blog.style-8 .main-post .info h4.title,
.blog.style-8 .side-posts .item .info h4.title {
    margin-top: 15px;
    font-size: 22px;
}

.blog-content {
    text-align: left;


}

.blog_card {
    border: 1px solid rgba(0, 0, 0, .125);
    transition: .4s;
    border-radius: 15px;
    height: 100%;
    background: #fff;
}
.blog_card h5 {

  margin-top: 15px;
  margin-bottom: 15px;
}
.blog_card a{

color: #333;

}

.blog_card img {
    border-radius: 15px;

}

.blog-cards {
    background-color: #f3fbff;
}

.blog h4 {
    font-size: 16px;
    color: #333;
}/* Blog.css */

.tabs {
    margin-bottom: 20px;
  }
  
  .tab-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 2px solid #ddd;
  }
  
  .tab-links li {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tab-links li.active,
  .tab-links li:hover {
    background: #22CEBA;
    color: #fff;
  }
  
  .blog-content .post {
    margin-bottom: 30px;
  }
  
  .blog-content .post img {
    width: 100%;
    height: auto;
  }
  
  .blog-content .btn-main {
    margin-top: 10px;
  }
  /* General table styling */
.jop-info table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 0.9rem;
}

/* Add borders to the table */
.jop-info table, .jop-info th, .jop-info td {
  border: 1px solid #ddd;
}

/* Styling the table header */
.jop-info th {
  background-color: #f2f2f2;
  padding: 12px;
  text-align: left;
}

/* Styling the table body */
.jop-info td {
  padding: 8px;
}

/* Make tables responsive */
.jop-info table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

/* Optional: Add some hover effects */
.jop-info tr:hover {
  background-color: #f5f5f5;
}

/* Responsive Font Sizes */
@media screen and (max-width: 768px) {
  .jop-info th, .jop-info td {
      font-size: 0.8rem;
      padding: 10px;
  }
}
.custom-color-class{
  color: #5dc4eb!important;
  font-size: 14px;
}
/* Responsive Font Sizes */
@media screen and (max-width: 768px) {
  .tab-links li {
      font-size: 0.5rem!important;
      padding: 5px 10px;
      font-weight: 500;
      display: flex;
      align-items: center !important;
      justify-content: center; /* Yeh horizontally center karega */
      
  }

  .mob-blog {
    justify-content: unset !important;
    flex-wrap: nowrap !important;
  }
}

.side-posts h4 a,
.main-post h4 a {
  color: #133d63;
}
