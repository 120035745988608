
  
  .content-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
  }
  
  .get-started-content {
   text-align: left;
    line-height: 1.6;
  }
  
  .link {
    color: #5dc4eb;
    text-decoration: none;
  }
  
  h1, h2, h3 {
    color: #333;
  }
  
  @media (max-width: 768px) {
    .get-started-container {
      padding: 10px;
    }
  
    .content-wrapper {
      padding: 20px;
    }
  }
  .contact {
    background: linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f347, transparent);
    background-size: 400% 400%;
    animation: 20s linear infinite gradient;
    border-radius: 30px;
    overflow: hidden;
    padding-bottom: 20px;
}